/**
 * This module contains predicate functions related to case records.
 * Predicates are used to determine specific properties or conditions of case records.
 */

import { isNullish } from '@kk/shared/utils/type-guards';
import { caseApi } from 'src/api';

/**
 * Determines whether a file has an Outlook file extension.
 * @param {string | null} fileExtension - The file extension to check.
 * @returns {boolean} - True if the file extension is an Outlook file extension (ost, pst, msg, eml), false otherwise.
 */
export function isOutlookFile(fileExtension: string | null = ''): boolean {
  if (!fileExtension) return false;
  return ['ost', 'pst', 'msg', 'eml'].includes(fileExtension.toLowerCase());
}

/**
 * Determines whether a case record is a customer response.
 * @param {caseApi.CaseRecordModel} caseRecord - The case record to check.
 * @returns {boolean} - True if the case record is a customer response, false otherwise.
 */
export function isCustomerResponse(
  caseRecord?: caseApi.CaseRecordModel | null,
): boolean {
  return Boolean(
    caseRecord?.casePhaseCode === caseApi.CasePhaseCode.AgreementConfirmation &&
      caseRecord.isActive &&
      caseRecord.recordGroupCode === caseApi.RecordGroup.CustomerConfirmation &&
      isOutlookFile(caseRecord.recordExtension),
  );
}
/**
 * Checks if a contract agreement is generated for a given case record.
 * @param {caseApi.CaseRecordModel} caseRecord - The case record to check.
 * @returns {boolean} - Returns true if the case record represents a generated contract agreement, otherwise returns false.
 */
export function isGeneratedContract(
  caseRecord?: caseApi.CaseRecordModel | null,
): boolean {
  return Boolean(
    caseRecord?.casePhaseCode === caseApi.CasePhaseCode.AgreementGeneration &&
      caseRecord.isActive &&
      caseRecord.recordExtension?.toLowerCase() === 'pdf',
  );
}

/**
 * Determines whether a case record is a viable candidate to mark as a customer response.
 * @param {caseApi.CaseRecordModel} caseRecord - The case record to check.
 * @returns {boolean} - True if the case record is a viable customer response, false otherwise.
 */
export function isViableCustomerResponse(
  caseRecord: caseApi.CaseRecordModel,
): boolean {
  return Boolean(
    isNullish(caseRecord.casePhaseCode) &&
      caseRecord.isActive &&
      isOutlookFile(caseRecord.recordExtension),
  );
}

/**
 * Determines whether a case record is a calculation record.
 * @param {caseApi.CaseRecordModel} caseRecord - The case record to check.
 * @returns {boolean} - True if the case record is a calculation record, false otherwise.
 */
export function isCalculcationRecord(
  caseRecord: caseApi.CaseRecordModel,
): boolean {
  return Boolean(
    caseRecord.casePhaseCode === caseApi.CasePhaseCode.Calculation &&
      caseRecord.recordExtension?.toLowerCase() === 'pdf' &&
      caseRecord.recordGroupDescription?.toLowerCase() === 'beregning',
  );
}
