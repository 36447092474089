import { KnownThemes } from '@kk/ui/styles/themes';

let theme: KnownThemes['variant'] | undefined = undefined;

/**
 * Set the current theme variant. This should be set initially in the app, since it will not trigger a rerender
 */
export function setTheme(newTheme: KnownThemes['variant']) {
  theme = newTheme;
}

/**
 * Get the current theme variant
 */
export function useTheme() {
  return theme;
}
