import {
  useMutation,
  type MutationObserverOptions,
} from '@tanstack/react-query';
import { caseApi } from '@/api';
import { ErrorResponse } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { CASE_SCOPES } from '@/api/auth/permissions';
import { handleErrorNotification, PermissionError } from '@/api/errors';
import { getAllActiveCasesQuery } from '@/api/hooks/case/useAllActiveCases';
import { getCaseQuery } from '@/api/hooks/case/useCase';
import { getCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { getContractCasesQuery } from '@/api/hooks/case/useContractCases';
import queryClient from '@/api/queryClient';
import { getLoanQuery } from '../loan/useLoan';
import { getAllActiveCasesLiteQuery } from './useAllActiveCasesLite';
import { getCompanyCasesQuery } from './useCompanyCases';
import { getRecentlyViewedCasesQuery } from './useRecentlyViewedCases';

const mutationKey = CASE_SCOPES.changePhaseState;
const method = caseApi.putCaseByCaseIdPhasestates;
export type Response = Awaited<ReturnType<typeof method>>;
export type Request = Parameters<typeof method>;

type CasePhaseStateRequest = Request[1] | null | undefined;

export type CasePhaseStateQueryParams = {
  caseId?: string | null;
  companyId?: string | null;
  contractId?: string | null;
};

export type CasePhaseStateMutationParams = {
  casePhaseStateRequest?: CasePhaseStateRequest;
};

export function useUpdateCasePhaseState(
  params: CasePhaseStateQueryParams,
  options: MutationObserverOptions<
    Response,
    ErrorResponse,
    CasePhaseStateRequest
  > = {},
) {
  const canChangePhaseState = useHasPermission(mutationKey);
  return useMutation<Response, ErrorResponse, CasePhaseStateRequest>({
    mutationKey: [mutationKey, params.caseId],
    mutationFn: (casePhaseStateRequest) => {
      if (!canChangePhaseState) throw new PermissionError(mutationKey);
      if (!params.caseId) throw new TypeError('No caseId provided');
      if (!casePhaseStateRequest)
        throw new TypeError('No casePhaseStateRequest provided');
      return method(params.caseId, casePhaseStateRequest);
    },
    ...options,
    onError: (error, variables, context) => {
      if (typeof options.onError === 'function') {
        options.onError(error, variables, context);
      }
      handleErrorNotification(error);
    },
    onSuccess: async (data, variables, context) => {
      if (typeof options.onSuccess === 'function') {
        options.onSuccess(data, variables, context);
      }

      await Promise.allSettled(
        [
          getCaseQuery(params),
          getCaseAssigneesQuery(params),
          getAllActiveCasesQuery(),
          getAllActiveCasesLiteQuery(),
          getContractCasesQuery(params),
          getLoanQuery(params),
          getRecentlyViewedCasesQuery(),
          getCompanyCasesQuery({
            companyId: params.companyId,
            queryFilter: caseApi.QueryFilter.All,
          }),
        ].map((query) => queryClient.invalidateQueries(query)),
      );
    },
  });
}
