import React from 'react';
import { domAnimation, LazyMotion, m as Motion } from 'framer-motion';

export default domAnimation;

export function LazyMotionFeatures({
  children,
}: {
  children: React.ReactNode;
}) {
  return <LazyMotion features={domAnimation}>{children}</LazyMotion>;
}

export const m = Motion;
