import { handleSettledPromises } from '@kk/shared/utils/async';
import { assertParamsPrescence } from '@kk/shared/utils/validators';
import { useParams, useRouteLoaderData } from 'react-router';
import { caseApi, loanApi, orchestrationApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { assertNoLoaderErrors } from '@/api/errors';
import { fetchCaseQuery } from '@/api/hooks/case/useCase';
import { fetchCaseAssigneesQuery } from '@/api/hooks/case/useCaseAssignees';
import { fetchCompanyQuery } from '@/api/hooks/company/useCompany';
import { fetchCalculationFromStorageQuery } from '@/api/hooks/loan/useCalculationFromStorage';
import { fetchCalculationHolidaysQuery } from '@/api/hooks/loan/useCalculationHolidays';
import { fetchCreateStandaloneCalculationpath } from '@/api/hooks/loan/useCreateStandaloneCalculationpath';
import { fetchLoanQuery } from '@/api/hooks/loan/useLoan';
import { fetchPartialRedemptionDatesQuery } from '@/api/hooks/loan/usePartialRedemptionDates';

export type Params = {
  companyId?: string;
  contractId?: string;
  caseId?: string;
};

export type Search = {
  id: string;
};

export type LoaderData = Awaited<ReturnType<typeof loader>>;

export const id = 'calculationPage' as const;

export type RedemptionCalculatorLoaderData = Awaited<
  ReturnType<typeof redemptionCalculationLoader>
>;

async function redemptionCalculationLoader(
  eventCase: caseApi.EventCaseModel,
  opts?: RequestOpts,
) {
  const originalCalculationPath = eventCase.calculations?.find(
    (calculation) => calculation.displayId === 0,
  )?.calculationPath;

  const calculationData = await handleSettledPromises([
    fetchPartialRedemptionDatesQuery(
      {
        calculationPath: originalCalculationPath,
      },
      opts,
    ),
    ...(eventCase.calculations?.map((calculation) =>
      fetchCalculationFromStorageQuery(calculation, opts),
    ) ?? []),
  ]);

  assertNoLoaderErrors(id, calculationData);

  const [partialRedemptionDates, ...calculations] = calculationData;

  return {
    partialRedemptionDates,
    calculations,
  };
}

async function newLoanCalculationLoader(
  eventCase: caseApi.EventCaseModel,
  opts?: RequestOpts,
) {
  const calculations = await handleSettledPromises(
    eventCase.calculations?.map((calculation) =>
      fetchCalculationFromStorageQuery(calculation, opts),
    ) ?? [],
  );

  const calculationPath = await fetchCreateStandaloneCalculationpath({}, opts);
  const holidayData = await fetchCalculationHolidaysQuery(
    {
      calculationPath,
      holidayUsage: loanApi.HolidayUsage.NewLoan,
    },
    opts,
  );
  return {
    calculations,
    holidayData,
  };
}

type LoaderDataForNewLoan = Awaited<ReturnType<typeof loader>> &
  Awaited<ReturnType<typeof newLoanCalculationLoader>>;

export function isLoaderDataForNewLoan(
  result: ReturnType<typeof useCalculationPageLoaderData>,
): result is LoaderDataForNewLoan {
  if (
    result?.eventCase.eventDescription ===
    orchestrationApi.EventDescriptionCode.NewLoan
  ) {
    return true;
  }
  return false;
}

type LoaderDataForRedemption = Awaited<ReturnType<typeof loader>> &
  Awaited<ReturnType<typeof redemptionCalculationLoader>>;

export function isLoaderDataForRedemption(
  result: ReturnType<typeof useCalculationPageLoaderData>,
): result is LoaderDataForRedemption {
  if (
    result?.eventCase.eventDescription !==
    orchestrationApi.EventDescriptionCode.NewLoan
  ) {
    return true;
  }
  return false;
}

async function loader({
  params,
  opts,
}: {
  params: Params;
  opts?: RequestOpts;
}) {
  assertParamsPrescence(params);
  const { contractId, caseId, companyId } = params;

  const data = await handleSettledPromises([
    fetchCompanyQuery({ companyId }, opts),
    fetchLoanQuery({ contractId }, opts),
    fetchCaseQuery({ caseId }, opts),
    fetchCaseAssigneesQuery({ caseId }, opts),
  ]);

  assertNoLoaderErrors(id, data);
  const [company, loanInfo, eventCase, caseAssignees] = data;

  try {
    const calculatorDataForType =
      eventCase.eventDescription ===
      orchestrationApi.EventDescriptionCode.NewLoan
        ? await newLoanCalculationLoader(eventCase, opts)
        : await redemptionCalculationLoader(eventCase, opts);

    return {
      company,
      loanInfo,
      eventCase,
      caseAssignees,
      ...calculatorDataForType,
    };
  } catch (error) {
    console.error(error);
    throw new Error('Failed to load calculation page data');
  }
}

export const useCalculationPageLoaderData = () => useRouteLoaderData(id);

export const useCalculationPageParams = () => useParams<Params>();

export default loader;
