import React, {
  Fragment,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Combobox } from '@headlessui/react';
import useDebounce from '@kk/shared/hooks/useDebounce';
import { capitalizeFirstLetter } from '@kk/shared/utils/strings';
import { BaseButton, LinkButton } from '@kk/ui/components/Button';
import { Icon } from '@kk/ui/components/Icon';
import Tooltip from '@kk/ui/components/Tooltip';
import clsx from 'clsx';
import keycode from 'keycode';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { caseApi, loanApi } from '@/api';
import {
  MIN_SEARCH_QUERY_LENGTH,
  useSearchResults,
} from '@/api/hooks/search/useSearchResults';
import { useSettings } from '@/api/hooks/useSettings';
import { isCaseClosed } from '@/api/predicates/eventCase';
import {
  SearchCategories,
  structureData,
  type AutocompleteSearchResult,
} from './structureData';
import { useSavedSearch } from './useSavedSearch';

export function highlight(text: string, searchKey: string) {
  const highlightStart = text.indexOf(searchKey);
  const isFound = highlightStart !== -1;
  const firstPart = isFound ? text.substring(0, highlightStart) : text;

  const highlightedPart =
    isFound && text.slice(highlightStart, highlightStart + searchKey.length);

  const lastPart = isFound && text.substring(highlightStart + searchKey.length);

  return {
    firstPart,
    highlightedPart,
    lastPart,
  };
}

export function Search() {
  const { data: settings } = useSettings();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<HTMLUListElement | null>(null);

  const [inputValue, setInputValue] = useState('');
  const defferedInput = useDeferredValue(inputValue);
  const query = useDebounce<string>(defferedInput, 200, {
    leading: true,
    trailing: true,
    maxWait: 700,
  });

  const { data } = useSearchResults(
    { query, offset: 0, limit: 9 },
    {
      enabled: query.length >= MIN_SEARCH_QUERY_LENGTH,
    },
  );

  const search = useMemo(() => {
    if (data) {
      return structureData(data);
    }
  }, [data]);

  const showSavedSearchResults = query.length < MIN_SEARCH_QUERY_LENGTH;
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { savedSearch, addSavedSearch } = useSavedSearch();

  useHotkeys(
    'F7',
    (event) => {
      event.preventDefault();
      setOpen(true);
    },
    { enabled: open === false },
  );

  function getCategoryName(name?: string | null): string {
    switch (name) {
      case SearchCategories.Cases:
        return capitalizeFirstLetter(t('case_other'));
      case SearchCategories.Companies:
        return capitalizeFirstLetter(t('company_other'));
      case SearchCategories.Loans:
        return capitalizeFirstLetter(t('loan_other'));
      default:
        return name ?? '';
    }
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [query]);

  const onValueSelect = (value: AutocompleteSearchResult | null) => {
    setOpen(false);
    if (value === null) return;
    if (value.searchType === 'entity') {
      addSavedSearch(value);
      // go to selected entity page (loan, case, company)
      navigate(value.url);
      setInputValue('');
    }
  };

  const onInputFocus = () => {
    if (open) return;
    setOpen(true);
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setInputValue('');
    inputRef.current?.focus();
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!open) {
      setOpen(true);
    }
    switch (keycode(event.key)) {
      case keycode.codes.esc:
        event.preventDefault();
        event.stopPropagation();
        setOpen(false);
        return;
    }
  };

  return (
    <Combobox value={null} onChange={onValueSelect} nullable>
      <div className="relative" ref={wrapperRef}>
        <div className="relative rounded border-0 bg-white text-left">
          <button
            data-testid="header-search-button"
            className="absolute inset-y-2 left-2 flex items-center border-none p-0"
            tabIndex={-1}
            onClick={() => inputRef.current?.focus()}
          >
            <Icon
              type="magnify"
              className={open ? 'text-blue-20' : 'text-neutral-100'}
              size="small"
            />
          </button>
          <Combobox.Input
            name="search"
            type="search"
            value={inputValue}
            displayValue={() => inputValue}
            onKeyUp={handleKeyUp}
            onFocus={onInputFocus}
            onBlur={(event) => {
              if (
                event.relatedTarget instanceof HTMLElement &&
                event.relatedTarget.getAttribute('role') === 'option'
              ) {
                return;
              }
              if (
                event.relatedTarget instanceof HTMLButtonElement &&
                event.relatedTarget.getAttribute('name') === 'clear'
              ) {
                event.preventDefault();
                event.stopPropagation();
                return false;
              }
              window.requestAnimationFrame(() => {
                setOpen(false);
              });
            }}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
            ref={inputRef}
            autoComplete="off"
            className={clsx(
              'w-fit py-2 pl-10 pr-20 font-sans text-xs leading-3 md:w-[374px]',
              'focus:border-blue-20 rounded border-2 border-solid border-transparent outline-none',
            )}
            data-testid="autocomplete-input"
          />
          {open ? (
            <div className="absolute inset-y-2 right-2.5 top-2.5 border-none">
              <div className="flex items-center">
                {inputValue !== '' ? (
                  <BaseButton
                    role="button"
                    name="clear"
                    onClick={handleClear}
                    className="typ-button-xs mr-2 h-5 rounded px-0.5 !font-normal text-neutral-100"
                    data-testid="clear-button"
                  >
                    {t('search.clear')}
                  </BaseButton>
                ) : null}
                <span className="border-neutral-10 mr-2 h-5 border-r" />
                <BaseButton
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="rounded"
                >
                  <Icon
                    type="cross"
                    className="text-neutral-110 size-4"
                    size="small"
                  />
                </BaseButton>
              </div>
            </div>
          ) : null}
        </div>

        <Combobox.Options
          as="ol"
          static
          ref={optionsRef}
          hidden={!open}
          hold={false}
          className={clsx(
            'absolute max-h-[374px] w-full overflow-auto rounded bg-white shadow-lg',
            open ? 'block' : 'hidden',
          )}
        >
          {search?.length === 0 && !showSavedSearchResults ? (
            <div className="flex flex-col">
              <div className="relative flex h-8 cursor-default items-center px-4 italic">
                <span className="typ-ui">{t('search.noResults')}</span>
              </div>
              <div className="h-8">
                <LinkButton
                  compact
                  className="flex w-full justify-center"
                  label={t('search.startSearch')}
                  onClick={() => {
                    setInputValue('');
                    inputRef.current?.focus();
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              {showSavedSearchResults && savedSearch.length > 0 ? (
                <div className="typ-headline-2xs relative mb-1 cursor-default select-none list-none px-4 pb-1 pt-3 uppercase text-neutral-100">
                  {t('search.latestSearches')}
                </div>
              ) : null}
              {(showSavedSearchResults ? savedSearch : search)?.map(
                (result, index) => {
                  if (result.searchType === 'group') {
                    return (
                      <Combobox.Option
                        disabled
                        key={`${result.key}-${result.name}-${index}`}
                        className={clsx(
                          'text-neutral-110 relative flex h-8 items-end px-4',
                          'cursor-pointer list-none aria-disabled:cursor-default',
                        )}
                        value={result}
                      >
                        <div
                          className={
                            'mb-1 flex items-center text-xs font-medium'
                          }
                        >
                          <span className="w-[100px] overflow-hidden whitespace-nowrap bg-white pr-2">
                            {getCategoryName(result.name)}{' '}
                            {result.count && result.count > 0
                              ? `(${result.count})`
                              : null}
                          </span>
                          <div className="bg-neutral-110 h-[1px] w-[156px]" />
                        </div>
                      </Combobox.Option>
                    );
                  }

                  const { firstPart, highlightedPart, lastPart } = highlight(
                    result.name ?? '',
                    inputValue,
                  );

                  const isLoan = result.group === 'loans';
                  const supported = isLoan
                    ? settings?.loanProducts
                        ?.map((product) => product.toLowerCase())
                        .includes(
                          result.type?.toLowerCase() as loanApi.ContractLoanTypeEnum,
                        )
                    : true;

                  const isCase = result.group === 'cases';
                  const isClosedCase =
                    result.group === 'cases'
                      ? isCaseClosed(result.data as caseApi.EventCaseModel)
                      : false;

                  const showNotice = supported
                    ? isCase
                      ? isClosedCase
                      : false
                    : true;

                  return (
                    <Fragment key={`${result.key}-${result.name}-${index}`}>
                      <Combobox.Option
                        disabled={isLoan && !supported}
                        className={({ active }) =>
                          clsx(
                            'group relative flex h-8 cursor-pointer list-none items-center px-4',
                            showNotice ? '' : active ? 'bg-blue-5' : 'bg-white',
                          )
                        }
                        value={result}
                        onClick={(event) => {
                          event.preventDefault();
                          onValueSelect(result);
                          window.requestAnimationFrame(() => {
                            setOpen(false);
                            if (document.activeElement instanceof HTMLElement)
                              document.activeElement.blur();
                          });
                        }}
                      >
                        {({ active }) => {
                          return (
                            <div className="flex w-full justify-between">
                              <div className="typ-ui-sm text-neutral-60 truncate">
                                <Tooltip
                                  tooltip={
                                    isClosedCase
                                      ? t('closedCase')
                                      : t('unsupported')
                                  }
                                  enabled={showNotice}
                                >
                                  <>
                                    {firstPart && (
                                      <span
                                        className={clsx(
                                          'typ-ui',
                                          active
                                            ? 'text-blue-60'
                                            : 'text-neutral-110 group-aria-disabled:text-neutral-60',
                                        )}
                                      >
                                        {firstPart}
                                      </span>
                                    )}
                                    {highlightedPart && (
                                      <mark
                                        className={clsx(
                                          'typ-ui bg-transparent font-bold',
                                          active
                                            ? 'text-blue-60'
                                            : 'text-neutral-110 group-aria-disabled:text-neutral-60',
                                        )}
                                      >
                                        {highlightedPart}
                                      </mark>
                                    )}
                                    {lastPart && (
                                      <span
                                        className={clsx(
                                          'typ-ui',
                                          active
                                            ? 'text-blue-60'
                                            : 'text-neutral-110 group-aria-disabled:text-neutral-60',
                                        )}
                                      >
                                        {lastPart}
                                      </span>
                                    )}
                                    <span
                                      className={clsx(
                                        'typ-ui-sm',
                                        active
                                          ? 'text-blue-60'
                                          : 'text-neutral-60',
                                      )}
                                    >
                                      {` ${result.identifier} `}
                                    </span>
                                    {showNotice ? (
                                      <Icon
                                        type="alert-diamond"
                                        className="absolute right-2 top-1/2 mb-1 ml-1 block -translate-y-1/2 !bg-transparent"
                                        size="tiny"
                                      />
                                    ) : null}
                                  </>
                                </Tooltip>
                              </div>
                            </div>
                          );
                        }}
                      </Combobox.Option>
                    </Fragment>
                  );
                },
              )}
            </>
          )}
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
