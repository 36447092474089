import { useCallback, useMemo } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import {
  autoPlacement,
  autoUpdate,
  offset,
  useFloating,
} from '@floating-ui/react-dom';
import { Menu } from '@headlessui/react';
import Icon from '@kk/ui/components/Icon';
import Spinner from '@kk/ui/components/Spinner';
import StatusTag from '@kk/ui/components/StatusTag';
import {
  LazyAnimatePresence,
  m,
} from '@kk/ui/components/Transitions/LazyAnimatePresence';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { caseApi } from '@/api';
import { useUpdateCasePhaseState } from '@/api/hooks/case/useUpdateCasePhaseState';
import useMapPhaseStatus from '@/hooks/useMapPhaseStatus';

export function ChangeablePhaseState({
  eventCase,
  activePhase,
  companyId,
  contractId,
  className,
}: {
  eventCase: caseApi.EventCaseModel;
  activePhase: caseApi.CasePhaseModel | undefined;
  companyId: string | undefined;
  contractId: string | undefined;
  className?: string;
}) {
  const { t } = useTranslation();
  const mapPhaseStatus = useMapPhaseStatus();
  const { mutate: updateCasePhaseState, isPending } = useUpdateCasePhaseState({
    caseId: eventCase.caseId,
    companyId,
    contractId,
  });

  const { refs, floatingStyles } = useFloating({
    middleware: [
      autoPlacement({
        alignment: 'start',
        allowedPlacements: [
          'bottom-end',
          'bottom-start',
          'top-end',
          'top-start',
        ],
      }),
      offset(() => {
        return 8;
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const handleChange = useCallback(
    (phaseState: caseApi.CasePhaseState) => {
      if (!activePhase) {
        return;
      }
      updateCasePhaseState([
        {
          casePhaseCode: activePhase.casePhaseCode as caseApi.CasePhaseCode,
          casePhaseState: phaseState,
        },
      ]);
    },
    [activePhase, updateCasePhaseState],
  );

  const menuItems = useMemo(() => {
    switch (activePhase?.casePhaseCode) {
      case caseApi.CasePhaseCode.Calculation:
        return [
          {
            value: caseApi.CasePhaseState.Progress,
            label: <StatusTag status="in-progress" />,
          },
          {
            value: caseApi.CasePhaseState.PendingCustomer,
            label: (
              <StatusTag
                status="waiting"
                title={t('status.waiting', {
                  awaiting: t('company_one'),
                })}
              />
            ),
          },
          {
            value: caseApi.CasePhaseState.PendingAllocation,
            label: (
              <StatusTag
                status="waiting"
                title={t('status.waiting', {
                  awaiting: t('allocation'),
                })}
              />
            ),
          },
        ];

      default:
        return [];
    }
  }, [activePhase, t]);

  return (
    <Menu>
      {({ open }) => (
        <div className="relative inline-block">
          <Menu.Button
            as="button"
            className={clsx(className, 'flex items-center space-x-1 rounded', {
              'group/status': !isPending,
            })}
            ref={(node) => {
              refs.setReference(node);
            }}
          >
            <StatusTag {...mapPhaseStatus(activePhase, eventCase)} />
            {isPending ? (
              <Spinner className="text-neutral-60 h-5" size="small" />
            ) : (
              <Icon
                type="chevron-down-large"
                size="tiny"
                className="group-aria-expanded:rotate-180"
              />
            )}
          </Menu.Button>
          <LazyAnimatePresence>
            {open && isPending === false && (
              <FloatingPortal>
                <Menu.Items
                  as={m.div}
                  static
                  className="bg-neutral-0 absolute z-20 flex w-max flex-col rounded py-1 shadow-lg focus:ring-0"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  ref={refs.setFloating}
                  style={floatingStyles}
                >
                  {menuItems.map((item, index) => (
                    <Menu.Item key={item.value}>
                      {({ active, close }) => (
                        <button
                          type="button"
                          className={clsx(
                            'focus:bg-blue-10 hover:bg-neutral-5 px-2 py-1',
                            {
                              'bg-neutral-5': active,
                            },
                          )}
                          disabled={isPending}
                          onClick={() => {
                            handleChange(item.value);
                            close();
                          }}
                          aria-selected={
                            activePhase?.phaseStateCode === item.value
                              ? 'true'
                              : undefined
                          }
                        >
                          {item.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </FloatingPortal>
            )}
          </LazyAnimatePresence>
        </div>
      )}
    </Menu>
  );
}
