import { CalendarDate, parseDate } from '@internationalized/date';
import { differenceInYears, format } from 'date-fns';
import dateFormat from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import isToday from 'date-fns/isToday';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import { da } from 'date-fns/locale';

export const defaultDateFormat = 'dd.MM.yyyy';

/**
 * @param {(Date|string|number)} [date] - the date to format
 * @param {string=} [format='dd.MM.yyyy'] - the chosen format (defaults to 'dd.MM.yyyy')
 * @param {object=} [options] - options to pass to date-fns
 */
export function formatDate(
  date?: Date | number | string | null,
  format: string = defaultDateFormat,
  options?: Parameters<typeof dateFormat>[2],
): string | null {
  try {
    return date
      ? dateFormat(
          typeof date === 'string' ? new Date(date) : date,
          format,
          options || { locale: da },
        )
      : null;
  } catch (e) {
    return null;
  }
}

/**
 * Formats a date and time into a string representation.
 * @param date - The date and time value to format.
 * @param options - An optional configuration object.
 * @param options.showToday - A boolean indicating whether to display "Today" for the current date. Defaults to false.
 * @returns The formatted string representation of the date and time.
 *          Returns null or undefined if the input is invalid or empty.
 */
export function formatDateTime(
  date?: Date | number | string | null,
  options: {
    showToday?: boolean;
  } = {
    showToday: false,
  },
): string | null | undefined {
  try {
    if (date) {
      const value = date instanceof Date ? date : new Date(date);
      if (options?.showToday && isToday(value))
        return formatDate(value, `'I dag, kl.' p`);
      return formatDate(value, `${defaultDateFormat}', kl.' p`);
    }
  } catch (e) {
    return null;
  }
}

type FormatTimeMeasureOptions = {
  format?: 'long' | 'short' | string;
};

/**
 * Formats a time measure object into a string representation in Danish.
 * @param {object | null | undefined} measure - The time measure object.
 * @param {number | null} measure.years - The number of years.
 * @param {number | null} measure.months - The number of months.
 * @param {number | null} measure.days - The number of days.
 * @param {FormatTimeMeasureOptions} [options] - The formatting options.
 * @param {'long' | 'short'} [options.format='long'] - The format of the output string.
 * @returns {string} The formatted string representation of the time measure.
 */
export function formatTimeMeasure(
  measure?: {
    years?: number | null;
    months?: number | null;
    days?: number | null;
  } | null,
  options: FormatTimeMeasureOptions = {
    format: 'long',
  },
): string | null {
  const { years, months, days } = measure ?? {};
  const formattedYears = years ? `${years} år` : undefined;

  let formattedMonths: string | undefined = undefined;

  if (days === 0 && months === 0 && years === 0) {
    return null;
  }

  if (months) {
    if (options?.format === 'long') {
      formattedMonths = months === 1 ? `1 måned` : `${months} måneder`;
    } else {
      formattedMonths = `${months} mnd`;
    }
  }

  let formattedDays: string | undefined = undefined;

  if (days) {
    if (options?.format === 'long') {
      formattedDays = days === 1 ? `1 dag` : `${days} dage`;
    } else {
      formattedDays = `${days} d`;
    }
  }

  const resultArray = [formattedYears, formattedMonths, formattedDays].filter(
    Boolean,
  );

  if (resultArray.length > 1) {
    const lastItem = resultArray.pop();
    return (
      resultArray.join(', ') +
      ` ${options?.format === 'long' ? 'og' : '&'} ${lastItem}`
    );
  }

  return resultArray.join(', ');
}

/**
 * Returns a human-readable distance between two dates.
 *
 * @param {Date | number | string} fromDate - The starting date.
 * @param {Date | number | string} toDate - The ending date.
 * @returns {string} - The human-readable distance between the two dates.
 */
export function getHumanReadableDateDistance(
  fromDate: Date | number | string,
  toDate: Date | number | string,
): string {
  const fromTime = new Date(fromDate).getTime();
  const toTime = new Date(toDate).getTime();
  const distanceInMs = Math.abs(fromTime - toTime);
  const distanceInDays = Math.floor(distanceInMs / (1000 * 60 * 60 * 24));
  const distanceInYears = Math.floor(distanceInDays / 365);
  const remainingDays = distanceInDays - distanceInYears * 365;
  const distanceInMonths = Math.floor(remainingDays / 30);

  if (distanceInYears === 0) {
    return formatDistanceStrict(fromTime, toTime, {
      addSuffix: false,
      locale: da,
    });
  }

  const monthText = distanceInMonths === 1 ? 'måned' : 'måneder';
  const yearString = `${distanceInYears} år`;
  const monthString =
    distanceInMonths > 0 ? `, ${distanceInMonths} ${monthText}` : '';
  return `${yearString}${monthString}`;
}

/**
 * get the time between a given date and the current date in human readable form
 */
export function formatDateDistanceToNow(
  from?: Date | number | string,
): string | undefined {
  return from
    ? getHumanReadableDateDistance(new Date(from), new Date())
    : undefined;
}

/**
 * Get the time between two dates in human readable form
 */
export function formatDateDistance(
  from?: Date | number | string,
  to?: Date | number | string,
): string | undefined {
  return from && to
    ? formatDistanceStrict(new Date(from), new Date(to), { locale: da })
    : undefined;
}

/**
 * sort dates chronologically
 */
export const sortByDate = (a: string, b: string) =>
  Number(new Date(a)) - Number(new Date(b));

/**
 * get a predefined list of weekday names for a given locale
 * @param {string} [locale='da-DK'] - the locale to format for
 * @param {string} [format='long'] - the format to output the weekdays in:
 *'narrow' = 'M'
 *'short' = 'Mon'
 *'long' = 'Monday'
 */
export function getWeekDayNames(
  locale: string = 'da-DK',
  format: 'long' | 'short' | 'narrow' = 'long',
) {
  const intlLocale = new Intl.Locale(locale) as any;
  const weekInfo: {
    firstDay: number;
    weekend: [number, number];
    minimalDays: number;
  } =
    typeof intlLocale.getWeekInfo === 'function'
      ? intlLocale.getWeekInfo()
      : intlLocale.weekInfo;

  const formatter = new Intl.DateTimeFormat(locale, {
    weekday: format,
    timeZone: 'UTC',
  });
  const days = (
    weekInfo.firstDay === 1 ? [2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4, 5, 6, 7]
  ).map((day) => {
    const dd = day < 10 ? `0${day}` : day;
    return new Date(`2017-01-${dd}T00:00:00+00:00`);
  });
  return days.map((date) => formatter.format(date));
}

export function isWeekend(date: Date) {
  const day = new Date(date).getDay();
  return day % 6 === 0;
}

export function isValidDate(value?: unknown): boolean {
  try {
    if (typeof value === 'string') {
      const date = new Date(value);
      return date instanceof Date && !isNaN(date.getTime());
    } else {
      return value instanceof Date && !isNaN(value.getTime());
    }
  } catch (error) {
    return false;
  }
}

/**
 * Calculates and returns an updated Date within the boundary of the specified month and/or year.
 * If the current day exceeds the last day of the target month, it sets the date to the last day of the target month.
 *
 * @param currentDate The current Date.
 * @param targetYear The target year (optional, defaults to the current year).
 * @param targetMonth The target month indexed (optional, defaults to the current month).
 * @returns The updated Date within the specified month boundary.
 */
export function getUpdatedDateWithinMonthBoundary({
  currentDate,
  targetYear,
  targetMonth,
}: {
  currentDate: Date;
  targetYear?: number;
  targetMonth?: number;
}) {
  const currentDay = currentDate.getDate();
  const targetDate = new Date(currentDate);
  targetDate.setDate(1);
  targetDate.setFullYear(targetYear ?? currentDate.getFullYear());
  targetDate.setMonth(targetMonth ?? currentDate.getMonth());
  const lastDayInTargetMonth = lastDayOfMonth(targetDate).getDate();
  if (currentDay > lastDayInTargetMonth) {
    targetDate.setDate(lastDayInTargetMonth);
  } else {
    targetDate.setDate(currentDay);
  }
  return targetDate;
}

/**
 * Parses a Date object to a CalendarDate.
 * @param {Date} date - The input date object to be parsed.
 * @returns {CalendarDate | undefined} The parsed CalendarDate or undefined if the input is invalid.
 */
export function parseDateToCalendarDate(date: Date): CalendarDate | undefined {
  const isoString = date.toISOString().split('T')[0];
  return !!isoString?.length ? parseDate(isoString) : undefined;
}

/**
 * Formats a given date to the 'yyyy-MM-dd' format.
 * @param {Date} date - The date to be formatted.
 * @returns {string} The formatted date in 'yyyy-MM-dd' format.
 */
export function formatToYearMonthDay(date: Date | string): string {
  if (typeof date === 'string') {
    return format(new Date(date), 'yyyy-MM-dd');
  }
  return format(date, 'yyyy-MM-dd');
}

/**
 * Formats a date to the full locale string in Danish format.
 * @param {Date} [date] - The date to be formatted (optional).
 * @returns {string | undefined} The date in full locale string format or undefined if no date is provided.
 */
export function formatDateToFullLocaleString(
  date: Date | undefined,
  locale: string = 'da-DK',
) {
  if (!date) return undefined;
  return date.toLocaleDateString(locale, {
    dateStyle: 'full',
  });
}

type ISODateTime = `${string}T${string}`;
export function stripTimestampFromISOString(
  isoDatetime: ISODateTime | string,
): ISODateTime {
  return `${isoDatetime.split('T')[0]}T00:00:00Z`;
}

/**
 * Trims the timestamp from an ISO 8601 formatted date string,
 * returning only the date part in 'YYYY-MM-DD' format.
 *
 * @param {ISODateTime | string} isoDatetime - The ISO 8601 date string
 *   from which the timestamp will be removed. It can be a string
 *   representing an ISO date-time or a custom type `ISODateTime`.
 *
 * @returns {string | undefined} - The date part of the ISO date-time string
 *   in 'YYYY-MM-DD' format, or `undefined` if the input is not a valid string.
 */
export function trimTimestampFromISOString(
  isoDatetime: ISODateTime | string,
): string | undefined {
  return isoDatetime.split('T')[0];
}

/**
 * Calculates the difference in years between two dates. The time component of the dates is ignored.
 *
 * @param {Date} date1 - The first date for comparison.
 * @param {Date} date2 - The second date for comparison.
 * @returns {number | undefined} The difference in years between the two dates, or undefined if either date is invalid.
 */
export function differenceInYearsExcludingTime(date1: Date, date2: Date) {
  const date1Compare =
    date1 && new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const date2Compare =
    date2 && new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
  return date1Compare && date2Compare
    ? differenceInYears(date1Compare, date2Compare)
    : undefined;
}
