import { z } from 'zod';

const isDeployedToProduction =
  globalThis.location.hostname === 'laaneportal.kommunekredit.dk';

export const flagSchema = z.object({
  /**
   * Enables notifications.
   * @feature Feature 27259
   * @see {@link https://dev.azure.com/kommunekredit/KK%20Laaneportal/\_workitems/edit/27259}
   */
  enableNotifications: z.boolean(),
  enableNewLoanCreation: z.boolean(),
});

export type Flags = z.infer<typeof flagSchema>;

const config: Flags = {
  enableNotifications: false,
  enableNewLoanCreation: isDeployedToProduction === false,
};

const featureFlags = flagSchema.parse(config);

export default featureFlags;
