import {
  keepPreviousData,
  queryOptions,
  useQuery,
} from '@tanstack/react-query';
import compareDesc from 'date-fns/compareDesc';
import ms from 'ms';
import { caseApi } from '@/api';
import { RequestOpts } from '@/api/api-types';
import { useHasPermission } from '@/api/auth/claims';
import { CASE_SCOPES } from '@/api/auth/permissions';
import { getCaseQuery } from '@/api/hooks/case/useCase';
import queryClient from '@/api/queryClient';
import { getContractCasesQuery } from './useContractCases';

export type Response = Awaited<ReturnType<typeof caseApi.getCasesActive>>;

export function getAllActiveCasesQuery(opts: RequestOpts = {}) {
  const queryKey = [CASE_SCOPES.getActiveCases];
  return queryOptions({
    queryKey,
    queryFn: async ({ signal }) =>
      caseApi
        .getCasesActive({
          ...opts,
          signal,
        })
        .then(addCasesToCache),
    meta: {
      showErrorToast: true,
    },
    retry: 1,
    retryDelay: 0,
    staleTime: ms('3m'),
    gcTime: ms('10m'),
    refetchInterval: ms('1m'),
    refetchOnWindowFocus: true,
    placeholderData: keepPreviousData,
    select: (data) =>
      data.sort((a, b) =>
        compareDesc(new Date(a.createDate ?? 0), new Date(b.createDate ?? 0)),
      ),
  });
}

/**
 * Adds cases to cache and updates contract cases in cache.
 *
 * @param {caseApi.EventCaseModel[]} data - Array of case models to be added to cache.
 * @returns {caseApi.EventCaseModel[]} - The input data array.
 */
function addCasesToCache(data: Response): Response {
  try {
    data.filter(Boolean).forEach((activeCase) => {
      queryClient.setQueryData(
        getCaseQuery({ caseId: activeCase.caseId }).queryKey,
        activeCase,
      );
    });
    const groupedCases = groupByContractId(data);
    // add active cases to cache for each contract
    if (Object.keys(groupedCases).length > 0) {
      Object.entries(groupByContractId(data)).forEach(([contractId, cases]) => {
        queryClient.setQueryData(
          getContractCasesQuery({ contractId, active: true }).queryKey,
          cases,
        );
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  } finally {
    return data;
  }
}

export async function fetchAllActiveCasesQuery(init: RequestOpts = {}) {
  return queryClient.fetchQuery(getAllActiveCasesQuery(init));
}

function groupByContractId(data: Response) {
  const result: Record<string, typeof data> = {};

  for (const activeCase of data) {
    if (!activeCase.contractId) continue;

    result[activeCase.contractId] = result[activeCase.contractId] || [];
    result[activeCase.contractId]?.push(activeCase);
  }

  return result;
}

export function useAllActiveCases(
  options?: Omit<ReturnType<typeof getAllActiveCasesQuery>, 'queryKey'>,
) {
  const canGetAllActiveCases = useHasPermission(CASE_SCOPES.getActiveCases);
  const query = getAllActiveCasesQuery();
  return useQuery({
    ...query,
    retry: 1,
    ...options,
    initialDataUpdatedAt: queryClient.getQueryState(query.queryKey)
      ?.dataUpdatedAt,
    enabled: canGetAllActiveCases,
  });
}

export default useAllActiveCases;
