import React from 'react';
import {
  AnimatePresence,
  AnimatePresenceProps,
  m as Motion,
} from 'framer-motion';
import { LazyMotionFeatures } from './LazyMotionFeatures';

type LazyAnimatePresenceProps = {
  children: React.ReactNode;
  initial?: boolean;
} & AnimatePresenceProps;

export function LazyAnimatePresence({
  children,
  initial,
  ...rest
}: LazyAnimatePresenceProps) {
  return (
    <LazyMotionFeatures>
      <AnimatePresence initial={initial} {...rest}>
        {children}
      </AnimatePresence>
    </LazyMotionFeatures>
  );
}

export const m = Motion;
